// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aerospace-js": () => import("./../../../src/pages/aerospace.js" /* webpackChunkName: "component---src-pages-aerospace-js" */),
  "component---src-pages-automotive-js": () => import("./../../../src/pages/automotive.js" /* webpackChunkName: "component---src-pages-automotive-js" */),
  "component---src-pages-cloud-solutions-js": () => import("./../../../src/pages/cloud-solutions.js" /* webpackChunkName: "component---src-pages-cloud-solutions-js" */),
  "component---src-pages-crm-software-js": () => import("./../../../src/pages/crm-software.js" /* webpackChunkName: "component---src-pages-crm-software-js" */),
  "component---src-pages-design-for-manufacturing-js": () => import("./../../../src/pages/design-for-manufacturing.js" /* webpackChunkName: "component---src-pages-design-for-manufacturing-js" */),
  "component---src-pages-electrical-design-js": () => import("./../../../src/pages/electrical-design.js" /* webpackChunkName: "component---src-pages-electrical-design-js" */),
  "component---src-pages-embedded-software-js": () => import("./../../../src/pages/embedded-software.js" /* webpackChunkName: "component---src-pages-embedded-software-js" */),
  "component---src-pages-erp-software-js": () => import("./../../../src/pages/erp-software.js" /* webpackChunkName: "component---src-pages-erp-software-js" */),
  "component---src-pages-free-project-evaluation-js": () => import("./../../../src/pages/free-project-evaluation.js" /* webpackChunkName: "component---src-pages-free-project-evaluation-js" */),
  "component---src-pages-hardware-js": () => import("./../../../src/pages/hardware.js" /* webpackChunkName: "component---src-pages-hardware-js" */),
  "component---src-pages-home-automation-js": () => import("./../../../src/pages/home-automation.js" /* webpackChunkName: "component---src-pages-home-automation-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-laboratory-js": () => import("./../../../src/pages/laboratory.js" /* webpackChunkName: "component---src-pages-laboratory-js" */),
  "component---src-pages-make-contact-js": () => import("./../../../src/pages/make-contact.js" /* webpackChunkName: "component---src-pages-make-contact-js" */),
  "component---src-pages-manufacturing-js": () => import("./../../../src/pages/manufacturing.js" /* webpackChunkName: "component---src-pages-manufacturing-js" */),
  "component---src-pages-mechanical-design-js": () => import("./../../../src/pages/mechanical-design.js" /* webpackChunkName: "component---src-pages-mechanical-design-js" */),
  "component---src-pages-medical-js": () => import("./../../../src/pages/medical.js" /* webpackChunkName: "component---src-pages-medical-js" */),
  "component---src-pages-military-js": () => import("./../../../src/pages/military.js" /* webpackChunkName: "component---src-pages-military-js" */),
  "component---src-pages-mobile-desktop-js": () => import("./../../../src/pages/mobile-desktop.js" /* webpackChunkName: "component---src-pages-mobile-desktop-js" */),
  "component---src-pages-prototype-design-js": () => import("./../../../src/pages/prototype-design.js" /* webpackChunkName: "component---src-pages-prototype-design-js" */),
  "component---src-pages-software-js": () => import("./../../../src/pages/software.js" /* webpackChunkName: "component---src-pages-software-js" */),
  "component---src-pages-who-we-are-js": () => import("./../../../src/pages/who-we-are.js" /* webpackChunkName: "component---src-pages-who-we-are-js" */)
}

